import { createInjectionState } from '@lasso/shared/utils'
import { AxiosInstance } from 'axios'
import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { ActivationApi, CancelablePromise, OpenAPIConfig } from './generated'

import { AxiosHttpRequest } from './generated/core/AxiosHttpRequest'
import { ApiRequestOptions } from './generated/core/ApiRequestOptions'
import { request } from './generated/core/request'

export const [useProvideActivationApi, useActivationApi] = createInjectionState(
  (axiosInstance: AxiosInstance, options: Partial<OpenAPIConfig> = {}) => {
    const [{ activation }, methodsMapping] = bindApiMethods(
      new ActivationApi(
        options,
        class AxiosHttpRequestInstance extends AxiosHttpRequest {
          public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
            return request(this.config, options, axiosInstance)
          }
        },
      ),
    )

    useApiCache().aliasCacheKeys(methodsMapping)

    return {
      // Dashboard
      getCampaignsStatistics: activation.postApiActivationGetCampaignsStatistics,
      getCampaignsStatisticsCsv: activation.postApiActivationGetCampaignsStatisticsCsv,
      getAccountLevelStats: activation.postApiActivationGetAccountLevelStats,
      getAdGroupsStatistics: activation.postApiActivationGetAdGroupStatistics,
      getAdGroupsStatisticsCsv: activation.postApiActivationGetAdGroupStatisticsCsv,

      // Campaign
      assignTrackingCodes: activation.postApiActivationAssignTrackingCodes,
      setCampaignData: activation.postApiActivationSetCampaignData,
      getCampaignById: activation.postApiActivationGetCampaignById,
      findBrands: activation.postApiActivationFindBrand,
      deleteAdGroup: activation.postApiActivationDeleteAdGroup,
      deleteCampaignFlights: activation.postApiActivationDeleteCampaignFlights,
      getCampaigns: activation.postApiActivationGetListOfCampaigns,
      deleteCampaign: activation.postApiActivationDeleteCampaign,
      toggleCampaignFavorite: activation.putApiActivationMakeCampaignFavorite,
      getCampaignFlightSchedule: activation.postApiActivationGetCampaignOverviewFlightScheduleData,
      setCampaignStatus: activation.postApiActivationSetCampaignStatus,

      // Campaign Competitive Separations
      addCompetitiveSeparations: activation.postApiActivationAddCompetitiveSeparations,
      deleteCompetitiveSeparations: activation.postApiActivationDeleteCompetitiveSeparations,
      getCampaignForCompetitiveSeparation: activation.postApiActivationGetCampaignsForCompetitiveSeparation,

      // AdGroup
      getAdGroupDetails: activation.postApiActivationGetAdGroupDetails,
      getAdGroupAudiences: activation.postApiActivationGetAdGroupAudiences,
      getAdGroupValidationMetadata: activation.getApiActivationGetValidationMetadata,
      linkAudiences: activation.postApiActivationLinkAudiences,
      setAdGroupData: activation.postApiActivationSetAdGroupData,
      getAdGroupFlights: activation.postApiActivationGetAdGroupFlights,
      getAdGroupDayparting: activation.postApiActivationGetAdGroupDayparting,
      setAdGroupDayparting: activation.postApiActivationSetAdGroupDayparting,
      deleteAdGroupFlights: activation.postApiActivationDeleteAdgroupFlights,
      switchAdGroupChannel: activation.postApiActivationSwitchAdgroupChannel,
      switchAdGroupStatuses: activation.postApiActivationSwitchAdGroupStatuses,
      getTotalCpm: activation.postApiActivationGetTotalCpm,
      toggleAdGroupFavorite: activation.putApiActivationMakeAdGroupFavorite,

      // AdGroup Inventory
      getAdGroupInventoryLists: activation.postApiActivationGetAdGroupInventoryLists,
      linkAdGroupInventoryLists: activation.postApiActivationLinkInventoryLists,

      // AdGroup Brand Safety
      getAdGroupBrandSafety: activation.postApiActivationGetAdGroupBrandSafety,
      linkAdGroupBrandSafety: activation.postApiActivationLinkBrandSafety,

      // AdGroup Deals
      getAdGroupDeals: activation.postApiActivationGetAdGroupDeals,
      linkAdGroupDeals: activation.postApiActivationLinkDeals,

      // AdGroup Frequency
      getAdGroupFrequencies: activation.postApiActivationGetAdGroupFrequencies,
      addAdGroupFrequencies: activation.postApiActivationAddAdGroupFrequencies,
      deleteAdGroupsFrequencies: activation.postApiActivationDeleteAdGroupsFrequencies,
      updateAdGroupFrequencies: activation.postApiActivationUpdateAdGroupFrequencies,

      // AdGroup Creatives
      getListOfCreativesByMarketer: activation.postApiActivationGetListOfCreativesByMarketer,
      getAdGroupCreatives: activation.postApiActivationGetAdGroupCreatives,
      linkAdGroupCreatives: activation.postApiActivationLinkAdGroupCreatives,

      // AdGroup Locations
      getAdGroupLocations: activation.postApiActivationGetAdGroupLocations,
      linkAdGroupLocations: activation.postApiActivationLinkLocations,

      // AdGroup Media Bid Strategy
      getAdGroupMediaBidStrategy: activation.postApiActivationGetAdGroupMediaBidStrategy,
      setMediaBidStrategy: activation.postApiActivationSetMediaBidStrategy,
      linkLevers: activation.postApiActivationLinkAdGroupCreativesLevers,

      // Dashboard
      getCampaignDetails: activation.postApiActivationGetCampaignDetailsData,
      getCampaignAdGroupsStatistics: activation.postApiActivationGetAdGroupStatisticsForCampaign,
      getCampaignAdGroupsStatisticsCsv: activation.postApiActivationGetAdGroupStatisticsForCampaignCsv,
      getCampaignOverviewStats: activation.postApiActivationGetCampaignOverviewStats,
      getCampaignDailyDelivery: activation.postApiActivationGetCampaignOverviewDeliveryData,
      getCampaignDeliveryLeaderboard: activation.postApiActivationGetCampaignOverviewLeaderboard,

      // Quick Stats
      getUserAdGroupsQuickStatsFilters: activation.getApiActivationGetUserAdGroupsQuickStatsFilters,
      setUserAdGroupsQuickStatsFilters: activation.postApiActivationSetUserAdGroupsQuickStatsFilters,

      // Locations
      getLocations: activation.postApiActivationGetLocations,
    }
  },
  { throwWhenNotProvided: 'Activation API was not provided' },
)
